import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import L from "leaflet";
import { Marker, useMap } from "react-leaflet";
import { navigate } from "gatsby";
import styled from "styled-components";
import { promiseToFlyTo, getCurrentLocation } from "lib/map";

import Layout from "components/Layout";
import Map from "components/Map";
import EventList from "components/EventList";

import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import { DateTime } from "luxon";
import { StaticImage } from "gatsby-plugin-image";
import EventDetails from "../components/EventDetails";
// const iconPerson = new L.Icon({
//   iconUrl: icon,
//   iconRetinaUrl: icon,
//   iconAnchor: null,
//   popupAnchor: [0, -25],
//   shadowUrl: null,
//   shadowAnchor: null,
//   iconSize: new L.Point(30, 50),
//   className: "leaflet-icon",
// });

const LOCATION = {
  lat: 30.4228398,
  lng: -87.3246071,
};

const LOCATION_TEST = [30.2828398, -87.6846071];
const gt = [30.2828398, -87.7846071];

const CENTER = [LOCATION.lat, LOCATION.lng];
const DEFAULT_ZOOM = 9;
const ZOOM = 13;

const timeToZoom = 5000;
const timeToOpenPopupAfterZoom = 4000;
const timeToUpdatePopupAfterZoom = timeToOpenPopupAfterZoom + 3000;

const SCMapHolder = styled.div`
  &.map-shrink {
    flex: 1;
  }

  flex: 4;
  min-height: 40%;
`;

const SCControls = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;

  button {
    height: 100%;
    min-width: 60px;
  }

  select {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 1.5rem;
  }
`;

const SCListHolder = styled.div`
  flex: 6;
  overflow-y: auto;
`;

/**
 * MapEffect
 * @description This is an example of creating an effect used to zoom in and set a popup on load
 */

const MapEffect = ({ markerRef }) => {
  const map = useMap();
  useEffect(() => {
    if (!markerRef.current || !map) return;

    (async function run() {
      const popup = L.popup({
        maxWidth: 800,
      });

      const location = await getCurrentLocation().catch(() => LOCATION);

      const { current: marker } = markerRef || {};

      marker.setLatLng(location);
      popup.setLatLng(location);

      setTimeout(async () => {
        await promiseToFlyTo(map, {
          zoom: ZOOM,
          center: location,
        });

        marker.bindPopup(popup);

        setTimeout(() => marker.openPopup(), timeToOpenPopupAfterZoom);
        // setTimeout(
        //   () => marker.setPopupContent(popupContentGatsby),
        //   timeToUpdatePopupAfterZoom
        // );
      }, timeToZoom);
    })();
  }, [map, markerRef]);

  return null;
};

MapEffect.propTypes = {
  markerRef: PropTypes.object,
};

const ArtistPage = ({ pageContext }) => {
  const events = pageContext.events;
  const artist = pageContext.artist;
  const markerRef = useRef([]);
  const myloc = useRef();
  const mapRef = useRef();

  const [activeEvent, setActiveEvent] = useState(null);

  const mapSettings = {
    center: CENTER,
    defaultBaseMap: "OpenStreetMap",
    zoom: DEFAULT_ZOOM,
  };

  useEffect(() => {
    (async () => {
      try {
        const tmp =
          (await getCurrentLocation().catch(() => console.log("err"))) || "";

        if (myloc) myloc.current.setLatLng(tmp);
      } catch (ex) {}
    })();
  }, []);

  return (
    <Layout pageName="venues">
      <Helmet>
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <title>Events</title>
      </Helmet>
      <SCMapHolder ref={mapRef}>
        <Map {...mapSettings}>
          {events.map((node, idx) => (
            <Marker
              eventHandlers={{
                click: (e) => {
                  setActiveEvent(node.node);
                },
              }}
              key={`marker-${idx}`}
              position={[node.node.venue.latitude, node.node.venue.longitude]}
            ></Marker>
          ))}
          {/* 
          <Marker ref={myloc} position={gt}></Marker> */}
        </Map>
      </SCMapHolder>

      <SCListHolder>
        <h1>{artist.artistName}</h1>

        <EventList
          setActiveEvent={setActiveEvent}
          events={events}
          displayType={"artist"}
        ></EventList>
      </SCListHolder>
      {activeEvent && (
        <Modal
          show={true}
          fullscreen={true}
          onHide={() => setActiveEvent(null)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Event Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EventDetails event={activeEvent}></EventDetails>
          </Modal.Body>
        </Modal>
      )}
    </Layout>
  );
};

export default ArtistPage;
